/* privacy.css */
.privacy-container {
  padding: 20px;
}

.privacy-header {
  background-color: #f4f4f4;
}

.privacy-section {
  margin-bottom: 30px;
  text-align: justify; /* Justify text */
}

.privacy-section h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.privacy-section p, .privacy-section ul {
  font-size: 1em;
  line-height: 1.6;
  text-align: justify; /* Justify text for paragraphs and lists */
}

.privacy-section ul {
  padding-left: 20px;
}

.privacy-section ul li {
  margin-bottom: 8px;
}
