/* General Styling */
.terms-container {
  background-color: white; /* Set background color to white */
  padding-top: 20px;
}
.terms-header h1 {
  font-size: 2.5rem;
  color: #212D65;
}

.terms-section h2 {
  color: #000000;
  font-size: 24px;
  text-align: justify;


}

.terms-section p,
.terms-section li {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  text-align: justify;

}

