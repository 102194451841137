.rightsec-title {
  font-size: 38px;
  margin-bottom: 20px;
  color: #000;
}

.rightsec-description {
  font-size: 1rem;
  text-align: justify;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.rightsec-img {
  width: 75%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.rightsec-section{
  padding: 50px 0px;
}