.contactuspg-bg {
  background-color: #f8f9fa;
  border-radius: 25px;
  padding-right: 25px;
}
  h2.contactuspg-heading-row {
    font-size: 2em;
    font-weight: 400px;
  }
  textarea.form-control.contactuspg-input {
    height: 200px !important;
  }
  
  .contactuspg-heading-row .contactuspg-heading {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .contactuspg-heading-row .contactuspg-subtext {
    font-size: 1.1rem;
    color: #666;
  }
  
  
  .contactuspg-info-box {
    padding: 100px 20px 100px 50px !important;
    background-color: #212D65;
    color: white !important;
    border-radius: 10px;
    width: 400px;
    place-self: center;
  }
  
  .contactuspg-info-box h3 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .contactuspg-info-box p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: white !important;

  }
  
  .contactuspg-info-box address {
    line-height: 1.8;
  }
  
  .contactuspg-link {
    color: white;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .contactuspg-link:hover {
    text-decoration: none;
  }
  
  .contactuspg-social-icons {
    margin-top: 20px;
  }
  
  .contactuspg-social-icons a {
    color: white;
    font-size: 1.5rem;
    margin-right: 15px;
    transition: 0.3s;
  }
  
  .contactuspg-social-icons a:hover {
    color: #ccc;
  }
  
  /* Form Styling */
  .contactuspg-form {
    border-radius: 10px;
  }
  
 
  
  
  
  .contactuspg-form button {
    background-color: #2780c3;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
  }
  
  .contactuspg-form button:hover {
    background-color:  #2780c3;
    color: #fff;
  }
  


  .contactuspg-input {
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 12px;
    border-radius: 4px; /* Slight rounded corners for a modern look */
    background-color: #ffffff00;
    font-size: 14px; /* Increased font size for better readability */
    color: #333; /* Darker text color for better contrast */
    transition: all 0.3s ease; /* Smooth transition for hover/focus effect */
  }
  
  /* Focus effect */
  .contactuspg-input:focus {
    outline: none; /* Remove default outline */
    border-bottom: 2px solid  #0056b3; /* Change bottom border color on focus */
    box-shadow: 0 2px 5px rgba(0, 123, 255, 0.3); /* Add subtle shadow for focus */
    background-color: #f0f8ff; /* Light background color change on focus */
  }
  
  /* Hover effect */
  .contactuspg-input:hover {
    border-bottom: 2px solid #0056b3; /* Darker border color on hover */
  }
  