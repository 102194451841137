/* Section Styling */
.tab-section {
  padding-top: 50px 0;
}

/* Container Styling */
.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Image Styling */
.image-container img {
  width: 75%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Content Styling */
.content-container h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.content-container .highlight {
  color:  #2780C3; /* Highlight color */
}

.content-container p {
  margin: 10px 0;
  text-align: justify;
  color: #555;
  line-height: 1.6;
}

.tabs {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.tab {
  padding: 10px 20px;
  font-size: 22px;
  border: none;
  background: none;
  color: #555;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: color 0.3s, border-color 0.3s;
}

.tab.active {
  color: #2780C3;
  border-color:  #2780C3;
}

.tab:hover {
  color: #2780C3;
}

.tab-content {
  margin-top: 20px;
  color: #333;
  font-size: 16px;
  line-height: 1.5;
}
.tab .icon {
  font-size: 34px;
}

.active-icon {
  color: #2780C3; /* Highlight the active icon */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  /* .row {
    flex-direction: column;
    text-align: center;
  }
  .image-container img {
    margin-bottom: 20px; 
  }

  .tabs {
    justify-content: center;
  } */
}
