.why-choose-app-dev{
  padding-top: 70px;
  padding-bottom: 70px;

 
}
.why-choose-app-dev h2 {
  color: #000000; /* Black heading color */
  font-size: 2rem;
}

.why-choose-app-dev p {
  color: #6c757d; /* Muted text color */
  font-size: 1rem;
}

.service-item img {
  width: 80%;
  height: auto;
  /* margin-right: 20px; */
}

.service-title {
  margin-top: 0.5rem;
  font-weight: 700 !important;
  font-size: 14px;
  color: #000;
}

@media (max-width: 768px) {
  .service-item {
    /* padding: 15px; */
  }
  .why-choose-app-dev{
    padding-top: 10px;
   
  }

  .service-item img {
    width: 100%;
    height: auto;
  }

  .service-title {
    font-size: 14px;
  }
}

