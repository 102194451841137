/* For screen 576px to 767px */
@media (max-width: 767.98px) {
    .footer-section {
        text-align: center;

        .social-links {
            justify-content: center;
        }
    }

}
