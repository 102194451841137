

.creative-minds-heading h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #010935;
}

.creative-minds-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.creative-minds-col {
  padding: 20px;
}

.creative-minds-icon {
  width: 80px; /* Adjust icon size */
}

.creative-minds-title {
  font-size: 20px;
  font-weight: 700 !important;
  color: #333;
}

.creative-minds-description {
  font-size: 1rem;
  color: #666;
}

.creative-minds-col img {
  margin-bottom: 20px; /* Spacing between icon and text */
}
