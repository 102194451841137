/* Unique Class Names for App Dev Values Section */
.app-dev-values-section-unique .app-dev-values-card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.app-dev-values-section-unique .card-body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  background-color: #f5f5f5;

}

.app-dev-values-section-unique .app-dev-values-card-title {
  font-weight: 700 !important;
  font-size: 18px;
}

.app-dev-values-section-unique .app-dev-values-card-text {
  font-size: 14px;
  color: #555;
}

.app-dev-values-section-unique .app-dev-values-icon {
  margin-right: 20px;
}

.app-dev-values-section-unique .app-dev-values-icon-image {
  width: 60px;  /* Adjust icon size */
  height: 60px;
  object-fit: contain;
}

.app-dev-values-section-unique .text-content {
  text-align: left;
}

.app-dev-values-section-unique .app-dev-values-title {
  font-size: 36px;
  font-weight: 800;
}

.app-dev-values-section-unique .app-dev-values-description {
  font-size: 16px;
  color: #777;
}

.app-dev-values-section-unique .container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.app-dev-values-section-unique .row {
  margin-bottom: 30px;
}

.app-dev-values-section-unique .col-md-4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .app-dev-values-section-unique .app-dev-values-title {
    font-size: 28px;
  }

  .app-dev-values-section-unique .app-dev-values-description {
    font-size: 14px;
  }

  .app-dev-values-section-unique .card-body {
    flex-direction: column;
    align-items: center;
  }

  .app-dev-values-section-unique .app-dev-values-icon-image {
    width: 40px;
    height: 40px;
  }
}
