/********** Template CSS **********/
body,
ul {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
}



:root {
    --primary: #2780C3;
    --secondary: #212D65;
    --light: #F0F6FF;
    --dark: #262B47;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Heading ***/
h1,
h2,
.fw-bold {
    font-weight: 700 !important;
}

h3,
h4,
.fw-semi-bold {
    font-weight: 600 !important;
}

h5,
h6,
.fw-medium {
    font-weight: 500 !important;
}


/*** Gradient Text & BG ***/
.text-primary-gradient {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-secondary-gradient {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-primary-gradient {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}

.bg-secondary-gradient {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
}


/*** Button ***/
.btn {
    transition: .5s;
}

.btn.btn-primary-gradient,
.btn.btn-secondary-gradient {
    position: relative;
    overflow: hidden;
    border: none;
    color: #FFFFFF;
    z-index: 1;
}

.btn.btn-primary-gradient::after,
.btn.btn-secondary-gradient::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: .5s;
    z-index: -1;
    opacity: 0;
}

.btn.btn-primary-gradient,
.btn.btn-secondary-gradient::after {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}

.btn.btn-secondary-gradient,
.btn.btn-primary-gradient::after {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
}

.btn.btn-primary-gradient:hover::after,
.btn.btn-secondary-gradient:hover::after {
    opacity: 1;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
    border: none;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}

/* General Header Styling */
.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    transition: background-color 0.3s ease;
    /* Smooth transition effect */
}

.sticky-header.scrolled {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    border-bottom-color: rgba(0, 0, 0, 0.1);
    /* Adjusted border color */
}

.header-container {
    max-width: 1400px;
    /* Constrain the width */
    margin: 0 auto;
    /* Center-align the header */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px;
    /* Inner padding */
}

.header-container a.active {
    border-bottom: 2px solid white;
    padding-bottom: 5px;
}

/* Logo */
.logo img {
    width: 100px;
    /* Adjust the width as needed */
    height: auto;
    /* Maintains aspect ratio */
    display: block;
    /* Ensures proper alignment */
}

/* Navigation Menu */
.menu ul {
    list-style: none;
    display: flex;
    gap: 50px;
    margin: 0;
    padding: 0;
}

.menu {
    display: none;
    position: absolute;
    /* Keep the menu out of normal flow */
    top: 100%;
    /* Adjust based on the header height */
    right: 0;
    background-color: white;
    /* Background color for the menu */
    width: 100%;
    /* Full-width for mobile */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    /* Ensure it stays above other elements */
}

.menu.open {
    display: flex;
    flex-direction: column;
}

@media (min-width: 992px) {
    .menu {
        display: flex !important;
        /* Always visible on desktop */
        position: static;
        /* Normal flow on desktop */
        width: auto;
        background-color: transparent;
        /* Match desktop style */
        box-shadow: none;
    }
}

.menu ul li a {
    text-decoration: none;
    color: #ffffff;
    /* White text for better visibility */
    font-size: 1rem;
    font-weight: 600;
    transition: color 0.3s ease;
}

.menu ul li a:hover {
    color: #fff;
    /* Hover color */
}

/* Header Button */
.header-button a {
    text-decoration: none;
    color: #fff;
    background: linear-gradient(45deg, #212D65, #2780C3);
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 0.9rem;
    font-weight: bold;
    transition: background 0.3s ease;
}

.header-button a:hover {
    background: #212D65;
}

/* Hide the button in mobile view */
@media screen and (max-width: 992px) {
    .header-button {
        display: none;
    }

    .menu ul {
        flex-direction: column;
        gap: 20px;
        display: none;
        /* Hide menu by default */
        position: absolute;
        top: 100%;
        /* Show below the header */
        left: 0;
        width: 100%;
        background-color: black;
        /* Match header background */
        padding: 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .menu ul.active {
        display: flex;
        /* Show menu when active */
    }

    .menu ul li {
        text-align: center;
    }

    .menu ul li a {
        font-size: 1.1rem;
    }

    .menu-toggle {
        display: block;
        cursor: pointer;
        font-size: 1.5rem;
        color: #ffffff;
    }
}

@media screen and (min-width: 993px) {
    .menu-toggle {
        display: none;
        /* Hide menu toggle on larger screens */
    }
}




/*** Screenshot ***/
.screenshot-carousel {
    position: relative;
    width: 253px;
    height: 500px;
    padding: 15px;
    margin-top: 25rem;
}

.screenshot-carousel::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url('images/mobile.png') center center no-repeat;
    background-size: 253px 500px;
    z-index: 1;
}

.screenshot-carousel .slick-slide img {
    position: relative;
    width: 223px;
    height: 470px;
    z-index: 2;
    /* Ensure the images appear above the background */
}

.screenshot-carousel .slick-dots {
    position: absolute;
    top: 50%;
    right: -150px;
    transform: translateY(-50%);
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
    /* Make sure dots appear above the images */
}

.screenshot-carousel .slick-dots li {
    margin: 5px 0;
}

.screenshot-carousel .slick-dots li button {
    display: none;
    width: 15px;
    height: 15px;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary)) !important;
    border-radius: 50%;
    transition: 0.5s;
    position: relative;
}

.screenshot-carousel .slick-dots li button:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    top: 5px;
    left: 5px;
    background: #FFFFFF;
    border-radius: 50%;
}

.screenshot-carousel .slick-dots li.slick-active button {
    box-shadow: 0 0 10px var(--dark);
}


/* Example custom CSS for carousel */
.slick-slide img {
    max-width: 100%;
    height: auto;
    display: block;
}






/*Start Banner Section */
.banner-section {
    position: relative;
    /* Make the section position relative */
    background: url('images/startbanner03.webp') no-repeat center center/cover;
    padding: 50px 0;
    /* Adjust padding as needed */
    color: #fff;
    /* Ensures text is readable on the background */
    height: 80vh;
    /* Adjust height to cover most of the viewport */
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* Stack the content vertically */
}

.overlay {
    position: absolute;
    /* Position the overlay absolutely */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.288);
    /* Black with 50% opacity */
    z-index: 1;
    /* Ensures the overlay is on top of the background */
}

.banner-section>.container {
    position: relative;
    /* Make sure the content is above the overlay */
    z-index: 2;
}


.banner-section h1 {
    color: #fff;
    /* Ensure text contrasts well with the background */
    margin-bottom: 20px;
    /* Space between heading and paragraph */
    font-size: 85px;
    line-height: 1.1em;
}

.banner-section p {
    color: #fff;
    /* Ensure text contrasts well with the background */
    margin-bottom: 20px;
    /* Space between heading and paragraph */
    font-size: 18px;
}

.btn-primary {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    border-color: #212D65;
    padding: 12px 24px;
    /* Customize button padding */
    font-size: 1rem;
    /* Font size for button */
    border-radius: 30px;
    /* Button border radius for a rounded look */
}

.btn-primary:hover {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
    border-color: #212D65;
}


/* Responsiveness for tablets */
@media (max-width: 990px) {
    .banner-section {
        padding: 80px 10px;
        /* Reduced padding for mobile */
        min-height: 82vh;
        justify-content: flex-start;
        /* Align content at the top of the screen */
    }

    .banner-section h1 {
        font-size: 40px;
        margin-bottom: 10px;
        font-weight: 700;
        margin-top: 110px;
        text-align: left;
    }


    .banner-section p {
        font-size: 16px;
        /* Smaller font size for mobile */
        margin-bottom: 15px;
        text-align: left;
    }

    .btn-primary {
        padding: 8px 16px;
        font-size: 0.9rem;
    }

    /* Adjusting the Image Section */
    .screenshot-carousel {
        margin-top: 20px;
        /* Adds spacing between content and image carousel */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    /* Adjusting the Content and Image Section for Mobile */
    .banner-section .container {
        display: block;
        /* Make the container block-level on mobile */
        text-align: center;
        /* Center all content */
    }

    .banner-section .row {
        display: block;
        /* Stack the content on top of each other */
    }

    .banner-section .col-lg-8,
    .banner-section .col-lg-4 {
        width: 100%;
        /* Ensure both sections take full width on mobile */
        margin-bottom: 20px;
        /* Add space between sections */
    }
}

/* Hide the dots in mobile view */
@media (max-width: 767px) {
    .screenshot-carousel .slick-dots {
        display: none !important;
    }
}

/* Responsiveness for mobile */
@media (max-width: 576px) {

    .banner-section h1 {
        font-size: 30px;
        margin-bottom: 10px;
        font-weight: 700;
        margin-top: 110px;
        text-align: left;
    }

    @media (max-width: 576px) {
        .acquire-app-image {
            padding-right: 0;
            justify-content: center;
            margin-top: 95px;
        }
    }

    .banner-section p {
        font-size: 0.8rem;
        /* Smaller font size for mobile */
        margin-bottom: 15px;
        text-align: left;
    }

    .btn-primary {
        padding: 8px 16px;
        font-size: 0.9rem;
    }

    /* Adjusting the Image Section */
    .screenshot-carousel {
        margin-top: 20px;
        /* Adds spacing between content and image carousel */
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    /* Adjusting the Content and Image Section for Mobile */
    .banner-section .container {
        display: block;
        /* Make the container block-level on mobile */
        text-align: center;
        /* Center all content */
    }

    .banner-section .row {
        display: block;
        /* Stack the content on top of each other */
    }

    .banner-section .col-lg-8,
    .banner-section .col-lg-4 {
        width: 100%;
        /* Ensure both sections take full width on mobile */
        margin-bottom: 20px;
        /* Add space between sections */
    }
}

@media (max-width: 576px) {
    .banner-section {
        padding: 50px 10px;
        /* Reduced padding for mobile */
        min-height: 85vh;
        justify-content: flex-start;
        /* Align content at the top of the screen */
    }

}

/*End Banner Section */


/*Start acquire-app-section */
.acquire-app-section {
    margin-top: 135px;
    margin-bottom: 50px;
}

.acquire-app-text {
    font-family: 'Montserrat', sans-serif;
    padding-left: 30px;
}

.acquire-app-text h2 {
    font-weight: 800;
    font-size: 42px;
    margin-bottom: 20px;
    color: #010935;
}

.acquire-app-text p {
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
    text-align: justify;
}

.acquire-app-bold-text {
    font-weight: bold;
    font-size: 18px;
    color: #512F90;
    margin: 20px 0;
}

.acquire-app-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 50px;
}

.acquire-app-image img {
    max-width: 90%;
    height: auto;
}

.acquire-app-button {
    display: inline-block;
    padding: 12px 25px;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    border-color: #212D65;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
    margin-top: 20px;
}

.acquire-app-button:hover {
    background: linear-gradient(to bottom right, var(--secondary), var(--primary));
    border-color: #212D65;
}

/* Responsive Styles */
/* For Tablets */
@media (max-width: 992px) and (min-width: 765px) {
    .acquire-app-section {
        margin-top: 150px;
        margin-bottom: 30px;
    }
}

@media (max-width: 766px) and (min-width: 556px) {
    .acquire-app-section {
        margin-top: 198px;
        margin-bottom: 30px;
    }
}

@media (max-width: 556px) and (min-width: 480px) {
    .acquire-app-section {
        margin-top: 220px;
        margin-bottom: 30px;
    }
}

@media (max-width: 481px) and (min-width: 395px) {
    .acquire-app-section {
        margin-top: 210px;
        margin-bottom: 30px;
    }
}

@media (max-width: 396px) and (min-width: 375px) {
    .acquire-app-section {
        margin-top: 250px;
        margin-bottom: 30px;
    }
}

@media (max-width: 376px) and (min-width: 320px) {
    .acquire-app-section {
        margin-top: 300px;
        margin-bottom: 30px;
    }
}

@media (max-width: 992px) {

    .acquire-app-text {
        padding-left: 15px;
    }

    .acquire-app-text h2 {
        font-size: 36px;
    }

    .acquire-app-text p,
    .acquire-app-bold-text {
        font-size: 16px;
    }

    .acquire-app-image {
        padding-right: 30px;
    }
}

/* For Mobile */
@media (max-width: 576px) {

    .acquire-app-text {
        padding-left: 10px;
        text-align: left;
    }

    .acquire-app-text h2 {
        font-size: 28px;
        text-align: left;
        margin-top: 12px;

    }

    .acquire-app-text p,
    .acquire-app-bold-text {
        font-size: 14px;
        text-align: left;

    }

    .acquire-app-image {
        padding-right: 0;
        justify-content: center;
    }

    .acquire-app-image img {
        max-width: 100%;
    }

    .acquire-app-button {
        font-size: 14px;
        padding: 10px 20px;
        margin-top: 15px;
    }
}

/* End acquire-app-section */


/* Start industries-section */

.section-heading {
    font-size: 36px;
    color: #010935;
}

.industries-section p {
    color: #6c757d;
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.industry-card {
    background-color: #fff;
    padding: 20px;
    /* Same padding on all sides */
    border-radius: 20px;
    /* Square corners */
    border: 1px solid rgb(0 0 0 / 18%)
        /* Same border on all sides */
}

.industry-card h3 {
    font-weight: 600;
    color: #010935;
    text-align: left;
    font-size: 24px;
    font-weight: 700;
}

.industry-card p {
    color: #6c757d;
    text-align: left;
    font-size: 14px;
}

.tags {
    margin-top: 10px;
    text-align: left;
}

.badge {
    margin: 2px;
    padding: 5px;
    background-color: #00000000;
    color: #202020da;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.486);
    /* Same border on all sides */
    border-radius: 20px;
    /* Square corners */
}

/* For screen 992px to 1199px */
@media (max-width: 1199.98px) {
    .industries-section .industries-description {
        text-align: center;
    }
}

@media (max-width: 767px) {
    .industry-card {
        margin-bottom: 20px;
    }

    .industries-section p {
        font-size: 14px;
    }
}

/* End industries-section */


/* Start uniquesection */
.unique-section-container {
    background: linear-gradient(to right, #f8f9fa, #f3f4f6);
    border-radius: 50px;
    padding: 15px 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.unique-section-text {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
}

.unique-section-link {
    color: #2780C3;
    font-weight: 700 !important;
    text-decoration: none;
}

.unique-section-link:hover {
    text-decoration: none;
}

.unique-section-button {
    border-radius: 50px;
}

/* End uniquesection */


/* Start EngagingUiCarouselSection */
.carousel-action-btns {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.custom-arrow-btn {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    border: none;
    padding: 10px 15px;
    border-radius: 50%;
    /* Make button circular */
    color: #fff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    /* Add shadow for a 3D effect */
    transition: all 0.3s ease-in-out;
    /* Smooth hover transition */
    cursor: pointer;
}

.custom-arrow-btn:hover {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    transform: scale(1.1);
    /* Slight zoom effect */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
    /* Enhanced shadow on hover */
}

.custom-arrow-btn svg {
    font-size: 20px;
    /* Adjust icon size */
}

.engaging-ui-carousel-section {
    padding: 50px 0;
}

.sub-heading {
    font-size: 24px;
    font-weight: bold;
    color: #010935;
    /* Heading color */
    margin-bottom: 10px;
}

.about-heading {
    font-size: 32px;
    font-weight: 700;
    color: #212D65;
    margin-bottom: 20px;
}

.our-work-img {
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    /* Smooth zoom on hover */
}

.our-work-img:hover {
    transform: scale(1.05);
    /* Slight zoom effect on hover */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.swiper-button-next,
.swiper-button-prev {
    display: none !important;
}

/* End EngagingUiCarouselSection */



/* Start ContactSection */
.custom-contact-section {
    background: url(images/form.png) no-repeat center center;
    background-size: 80%;
    /* This ensures the image covers the section properly */
    background-position: center;
    /* Centers the background image */
    padding: 2rem 0;
}

.text-primary-gradient {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.btn-primary-gradient {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    color: #fff;
    border: none;
}

/* end ContactSection */


/* Start footer */

.footer-section {
    background: linear-gradient(to bottom, #1c73b1, #0f4b78);
    background:
        url('images/bg-circle.png'),
        url('images/bg-triangle.png'),
        linear-gradient(to bottom right, var(--primary), var(--secondary));
    background-position:
        left 0px bottom 0px,
        right 0px top 50%,
        center top;
    background-repeat: no-repeat;
}

.footer-logo img {
    max-height: 50px;
}

.footer h5,
.footer h3 {
    font-weight: 600;
}

.footer p,
.footer ul {
    font-size: 0.9rem;
}

.footer ul li {
    margin-bottom: 10px;
    text-decoration: none;

}

.footer ul li a:hover {
    text-decoration: none;
}

.footer a {
    transition: all 0.3s ease;
    text-decoration: none;

}

.footer a:hover {
    color: #ffd700;
}

.footer .bi {
    font-size: 1.2rem;
}

/* Start footer */



/* start step */
.step-section h2 {
    font-size: 45px;

}

.step-section {
    padding: 50px 20px;
}

.step-content h2 {
    font-size: 24px;
    font-weight: 700;
}

.step-content p {
    font-size: 16px;
    text-align: justify;
}

.step-box {
    font-size: 50px;
    font-weight: 900;
    text-align: center;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.step-box span {
    font-size: 28px;
    font-weight: 600;
    color: #000000;
}

.step-box::after {
    content: '';
    width: 1px;
    height: 100px;
    background: #000;
    /* margin-top: 10px; */
}

.step-image img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    border: 3px solid #000;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    /* Adds a subtle shadow */
}

@media (max-width: 768px) {
    .step-box::after {
        display: none;
        /* Hide the pseudo-element on mobile devices */
    }

    .step-section h2 {
        text-align: center;
        font-size: 34px;
    }

    .step-box {
        font-size: 24px;
        margin-top: 20px;

    }

    .step-content h2 {
        font-size: 22px;
    }
}

/* end step */



/* start Frequently Asked Questions */

.faqs {
    width: 100%;
    margin: 0 auto;
    padding: 15px;
}

.faqs h2 {
    text-align: center;
}

.faqs .faq {
    margin: 15px;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    padding-right: 80px;
    transition: all 0.4s ease;
    cursor: pointer;
    /* Add pointer cursor */
}

.faqs .faq .faq-question::after {
    font-weight: 700;
    font-size: 18px;
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 18px;
}

.faqs .faq.open .faq-question::after {
    content: "-";
}

.faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
}

/* End Frequently Asked Questions */

/* Unique Work Process Section */
.unique-work-process {
    padding-top: 50px;
    overflow-x: hidden;
    /* Prevent horizontal scroll */
}

.unique-container {
    max-width: 1000px;
    margin: 0 auto;
}

.unique-heading {
    font-size: 44px;
    font-weight: bold;
}

.unique-description {
    font-size: 16px;
    color: #555;
    /* margin-bottom: 30px; */
}

.unique-step {
    text-align: center;
    margin-bottom: 30px;
}

.unique-card {
    border-radius: 25px;
}

.unique-card-body {
    padding: 20px;
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    border-radius: 25px;
    color: #fff;
}

.unique-step-number {
    font-size: 40px;
    font-weight: bold;
    color: #0066cc;
}

.unique-step-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 30px;
}

.unique-step-description {
    font-size: 14px;
    color: #fff;
}

.unique-rating {
    margin-top: 15px;
    font-size: 14px;
    color: #f39c12;
}

.unique-rating i {
    margin-right: 2px;
}

/* Responsive behavior for smaller screens */
@media (max-width: 768px) {
    .unique-step {
        margin-bottom: 20px;
        /* Reduce margin for smaller screens */
    }

    .unique-card-body {
        padding: 15px;
        /* Reduce padding on smaller screens */
    }
}

/* breadcrumb */

.breadcrumb-section {
    background-color: #212D65;
    position: relative;
    height: 500px;
    margin-top: -110px;
    padding-top: 100px;
    color: #fff;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.breadcrumb-section .heading {
    font-size: 45px;
    font-weight: bold;
    margin: 0;
}

.breadcrumb-section .description {
    font-size: 18px;
    margin: 10px 0;
}

/* For screen 992px to 1199px */
@media (max-width: 1199.98px) {
    .breadcrumb-section .breadcrumb-description {
        text-align: center;
    }
}



@media (max-width: 768px) {
    .breadcrumb-section {
        height: 400px;

    }

    .unique-container {
        max-width: 1000px;
        margin: 0 auto;
        padding: 0px 5px;
    }
}

.slick-prev,
.slick-next {
    display: none !important;
}

.right-image-section img {
    padding-right: 50px;
}
