/* General section styles */
.unique-carousel-section {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Boxed wrapper for carousel */
.unique-carousel-wrapper {
    width: 90%;
    max-width: 1200px;
    padding: 30px;
    border-radius: 12px;
}

/* Individual carousel item styling */
.unique-carousel-item {
    text-align: center;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
    outline: none;
    /* Prevent outline when clicked or focused */
}

.unique-carousel-item:hover {
    transform: scale(1.05);
}

/* Remove outline or border when focused or clicked */
.unique-carousel-item:focus,
.unique-carousel-item:active {
    outline: none;
    border: none;
}

.unique-carousel-logo {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
    user-select: none;
    /* Prevent selection of the image */
}

/* Caption styling */
.unique-carousel-caption {
    font-size: 18px;
    color: #555;
    font-weight: 600;
    margin-top: 10px;
    padding: 0 10px;
    line-height: 1.5;
}

/* Responsive styling */
@media (max-width: 1024px) {
    .unique-carousel-item {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .unique-carousel-item {
        padding: 10px;
    }
}

/* For screen 576px to 767px */
@media (max-width: 767.98px) {
    .unique-heading {
        font-size: 33px;
    }
}
