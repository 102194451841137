  
  .mobile-apps-section {
    padding: 40px 20px;
  }
  
  .mobile-apps-section {
    margin-bottom: 10px;
  }
  .mobile-apps-heading{
    font-weight: 700;
    margin-bottom: 50px;
    text-align: center;
    font-size: 45px;
  }
  
  
  .app-image {
    max-width: 100%;
    height: auto;
  }
  
  .btn-get-started {
    background-color: #007bff;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    margin-top: 20px;
  }
  
  .btn-get-started:hover {
    background-color: #0056b3;
    color: #fff;
  }
  .accordion-body{
    text-align: justify;
  }
  .accordion-item {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none; /* Remove top border */
}
.accordion-item {
    border: none; /* Removes the border around accordion items */
  }

  
  .accordion-button {
    border: none; /* Removes the border of the button */
    box-shadow: none; /* Removes the shadow around the button */
    font-weight: 700;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none; /* Ensures no shadow when the accordion is expanded */
    
  }
  .accordion-button:not(.collapsed) {
    color: inherit; /* Keeps the text color the same */
    background-color: transparent; /* Removes the background color */
    box-shadow: none; /* Removes the shadow effect */
  }