.right-image-section{
  padding-bottom: 50px;
}

.section-heading {
  font-size: 2rem;
  font-weight: 700;
  color: #010935; /* Custom heading color */
}

.section-description {
  font-size: 1rem;
  color: #555;
  text-align: justify;
}
